import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import * as style from '../styles/index.module.css'

function HomepageContents({ showMenu }) {
  return (
    <div className='page_contents'>
      <div className={style.center}>
        <div className={style.text}>
          <span>Finding</span>

          <div className={style.alternating_text}>
            <span className={style.noun}>Clarity</span>
            <span className={style.noun}>&nbsp;Justice</span>
            <span className={style.noun}>Resolution</span>
          </div>
        </div>

        <div
          className={style.button}
          role='button'
          tabIndex={0}
          onClick={showMenu}
          onKeyDown={e => e.code === 'Enter' && showMenu()}
        >
          HELPING YOU GET THERE
        </div>
      </div>
    </div>
  )
}

export default function Homepage() {
  return (
    <Layout showFooter={false}>
      <Seo />
      <HomepageContents />
    </Layout>
  )
}
